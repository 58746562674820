import React, { useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import "./Connect.css";

const Connect = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [state, handleSubmit] = useForm("mgegevgn");

  if (state.succeeded) {
    return <div className="whole"><p>Thanks for contacting us! We will get back to you as soon as possible</p></div>;
  }

  return (
    <div className="Connect">
      <div className="connect-details">
        <h1>GET IN TOUCH</h1>
        <p>Phone: +260 97 3536566</p>
        <p>Email: info.nicetyfarm@gmail.com, info@nicetytech.tech</p>
        <p>Nkwashi Estates, Plot 002, Chongwe, Lusaka, Zambia</p>
      </div>
      <div className="connect-form">
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          action="https://formspree.io/f/mgegevgn"
          method="POST"
        >
          <div className="div">
            <div className="an">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  placeholder="Enter your name"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <ValidationError
                className="error"
                  prefix="Name"
                  field="name"
                  errors={state.errors}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  name="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <ValidationError
                className="error"
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <input
                  type="text"
                  name="phone"
                  placeholder="Enter your phone number"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
                <ValidationError
                className="error"
                  prefix="Phone"
                  field="phone"
                  errors={state.errors}
                />

                {error ? (
                  <p className="error">
                    {" "}
                    Please enter the correct number format{" "}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="an">
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  name="message"
                  value={message}
                  placeholder="Enter your message"
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
                <ValidationError
                    className="error"
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                />
              </div>
            </div>
          </div>
          <button type="submit" disabled={state.submitting}>
            Send Message
          </button>
        </form>
      </div>
    </div>
  );
};

export default Connect;
