import React from "react";
import "./Products.css";
import eyris from "../../assets/eyris.jpg";
import nicetylab from "../../assets/lab.jpg";

const Products = () => {
  return (
    <section class="portfolio section" id="portfolio">
      {/* <h2 class="section__title">Portfolio</h2>
      <span class="section__subtitle">Most recent work</span> */}

      <div class="portfolio__container container swiper-container">
        <div class="swiper-wrapper">
          

          <div class="portfolio__content grid swiper-slide">
            <img src={nicetylab} alt="" class="portfolio__img" />
            <div class="portfolio__data">
              <h3 class="portfolio__title">NicetyLab</h3>
              <p class="portfolio__description">
                A web application that provides practical lessons to high school & University
                science students through gamification and extended reality.
              </p>
              <a
                href="/login"
                class="button button--flex button--small portfolio__button"
              >
                Visit
                <i class="uil uil-arrow-right button__icon"></i>
              </a>
            </div>
          </div>

          <div class="portfolio__content grid swiper-slide">
            <img src={eyris} alt="" class="portfolio__img" />
            <div class="portfolio__data">
              <h3 class="portfolio__title">Eyris</h3>
              <p class="portfolio__description">
              A smartphone-based, non-invasive technology for
blood sugar monitoring and early detection of eye damage
              </p>
              <a
                href="https://eyris-com.web.app/"
                class="button button--flex button--small portfolio__button"
              >
                Visit
                <i class="uil uil-arrow-right button__icon"></i>
              </a>
            </div>
          </div>
          
        </div>

        <div class="swiper-button-next">
          <i class="uil uil-angle-right-b swiper-portfolio-icon"></i>
        </div>
        <div class="swiper-button-prev">
          <i class="uil uil-angle-left-b swiper-portfolio-icon"></i>
        </div>

        <div class="swiper-pagination"></div>
      </div>
    </section>
  );
};

export default Products;
