import React from "react";
import "./Services.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode, faGamepad, faVrCardboard, faBlog } from "@fortawesome/free-solid-svg-icons";

const code = <FontAwesomeIcon icon={faCode} />;
const game = <FontAwesomeIcon icon={faGamepad} />;
const vr = <FontAwesomeIcon icon={faVrCardboard} />;
const block = <FontAwesomeIcon icon={faBlog} />;

const Services = () => {
  return (
    <div className="Services">
      <div className="service-card">
        <div className="service-icon">
            {code}
        </div>
        <h1>Web Development</h1>
        {/* <p>
          We build websites and web applications that are fast, secure, and
          scalable.
        </p> */}
        <button>Request</button>
      </div>

      <div className="service-card">
        <div className="service-icon">
            {code}
        </div>
        <h1>Hybrid App Development</h1>
        
        <button>Request</button>
      </div>

      <div className="service-card">
        <div className="service-icon">
            {vr}
        </div>
        <h1>XR App Development</h1>
        {/* <p>
          We build websites and web applications that are fast, secure, and
          scalable.
        </p> */}
        <button>Request</button>
      </div>

      <div className="service-card">
        <div className="service-icon">
            {game}
        </div>
        <h1>3D Game Development</h1>
        {/* <p>
          We build websites and web applications that are fast, secure, and
          scalable.
        </p> */}
        <button>Request</button>
      </div>

      <div className="service-card">
        <div className="service-icon">
            {block}
        </div>
        <h1>Blockchain Development</h1>
        {/* <p>
          We build websites and web applications that are fast, secure, and
          scalable.
        </p> */}
        <button>Request</button>
      </div>
    </div>
  );
};

export default Services;
