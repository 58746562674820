import React from "react";
import "./Hero.css";
import video from "../../assets/techBg.mp4";
import Nav from "../Nav/Nav";
import { Outlet } from "react-router-dom";

const Hero = () => {
  return (
    <div className="Hero">
        <div className="overlay"></div>
        <video src={video} autoPlay muted loop ></video>
        <div className="content">
          <Nav />
      
          <Outlet />
        </div>
    </div>
  );
};

export default Hero;