import React, { useState } from "react";
import ice from "../../assets/ice.png";
import mirram from "../../assets/mirriam.png";
import enerst from "../../assets/enerst.png";
import sharon from "../../assets/sharon.png";
// import "./Connect.css";

const Team = () => {
  

  return (
    <div className="Connect">
      <div className="connect-details">
        <h1>OUR LEADERSHIP</h1>
        <p>With over 20 years of combined experience, we've got a well seasoned team at the helm</p>
      </div>
      <div className="team-details">
        <div className="team">
          <div className="team-member">
            <img src={ice} alt="" />
            <h1>Justine Imasiku</h1>
            <p>Founder & CEO</p>
          </div>
          <div className="team-member">
          <img src={sharon} alt="" />
            <h1>Sharon Zulu</h1>
            <p>Co-Founder & COO</p>
          </div>
          <div className="team-member">
            <img src={mirram} alt="" />
            <h1>Mirram Sikalume</h1>
            <p>Co-Founder & CTO</p>
          </div>
          <div className="team-member">
            <img src={enerst} alt="" />
            <h1>Enerst Inyambo</h1>
            <p>Co-Founder & CMO</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
