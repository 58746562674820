import React from "react";
import "./Intro.css";
import Products from '../Products/Products'

const Intro = () => {
  return (
    <div className="intro">
      <h1>Transforming the Future through Innovative Technologies</h1>
      <p>
        At Nicetyfarm Technologies we are a team of talented design thinkers, developers and innovators making the world a better place
        through technology.
      </p>
      <div className="buttons">
        <a href="/products" className=".btn">
          Explore Our Products
        </a>
        <a href="/connect" className=".btn">
          Contact Us
        </a>
      </div>
      <Products />
    </div>
  );
};

export default Intro;
