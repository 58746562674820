import React from "react";
import "./Products.css";

const Nicetylab = () => {
  return (
    <section class="Inprogress" id="portfolio">
       <iframe src="https://hubs.mozilla.com/5RzN2hq?embed_token=5960d8c58ffbc4eb9fd66dee5ccd591b" allow="microphone; camera; vr; speaker;"></iframe>
    </section>
  );
};

export default Nicetylab;
