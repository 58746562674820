import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Products from "./components/Landing/Products/Products";
import Intro from "./components/Landing/Intro/Intro";
import Services from "./components/Landing/Services/Services";
import Hero from "./components/Landing/Hero/Hero";
import Nicetylab from "./components/Landing/Products/NicetyLab";
import Connect from "./components/Landing/Connect/Connect";
import Team from "./components/Landing/Team/Team";
import Login from "./components/Landing/Products/Login";

class App extends Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Hero />}>
            <Route path="/" element={<Intro />} />
            <Route path="/products" element={<Products />} />
            <Route path="/services" element={<Services />} />
            <Route path="/connect" element={<Connect />} />
            <Route path="/team" element={<Team />} />
          </Route>
          
          <Route path="/nicetylab" element={<Nicetylab />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
    );
  }
}

export default App;
