import React, { useRef } from "react";
import "./Nav.css";
import logo from "../../assets/Nicety1.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const menu = <FontAwesomeIcon icon={faBars} />;
const close = <FontAwesomeIcon icon={faTimes} />;

const Nav = () => {

  const menuRef = useRef();
	const navRef = useRef();
	const logoRef = useRef();

  const toggleMenu = () => {
    console.log(logoRef, menuRef, navRef);
    menuRef.current.classList.toggle("none");
    navRef.current.classList.toggle("none");
    logoRef.current.classList.toggle("none");
  }


  return (
    <div className="Nav">
      <div className="desktop logo hey">
        <h1 className="lg">
          <img src={logo} alt="logo" /> Nicetyfarm Technologies Limited
        </h1>
      </div>
      <div className="mobile logo hey" ref={logoRef}>
        <h1>
          <img src={logo} alt="logo" />
        </h1>
      </div>
      <ul className="nav-elements">
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a href="/products">Products</a>
        </li>
        <li>
          <a href="/services">Services</a>
        </li>
        {/* <li>
          <a href="/pricing">Pricing</a>
        </li> */}
        <li>
          <a href="/team">Team</a>
        </li>
        <li>
          <a href="/connect">Connect Us</a>
        </li>
      </ul>
      <div className="nav-elements2">
        <button className="humburger hey btn1" ref={menuRef} onClick={() => {toggleMenu()}}>{menu}</button>

        <ul className="mobile-ul none" ref={navRef} onClick={() => {toggleMenu()}}>
        <button className="humburger btn1">{close}</button>
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a href="/products">Products</a>
        </li>
        <li>
          <a href="/services">Services</a>
        </li>
        {/* <li>
          <a href="/pricing">Pricing</a>
        </li> */}
        <li>
          <a href="/team">Team</a>
        </li>
        <li>
          <a href="/connect">Connect Us</a>
        </li>
        </ul>
      </div>
    </div>
  );
};

export default Nav;
